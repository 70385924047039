import React from "react"
import Proptypes from "prop-types"

export default function Information(props) {
  const { title, info } = props
  const renderInfo = () => {
    return (
      info &&
      info.map(item => {
        return <p className="InformationItem-text">{item}</p>
      })
    )
  }
  return (
    <div className="InformationItem">
      <span className="InformationItem-title">{title}</span>
      {renderInfo()}
    </div>
  )
}

Information.propTypes = {
  title: Proptypes.string,
  info: Proptypes.array,
}
