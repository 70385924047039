import React from "react"
import PropTypes from "prop-types"

const Message = props => {
  const { text, type } = props

  return <div className={`Message ${type}`}>{text}</div>
}

Message.propType = {
  text: PropTypes.string,
  type: PropTypes.string,
}

Message.defaultProps = {
  text: "Default Text",
  type: "sucess",
}

export default Message
