import React from "react"
import Breadcrumb from "../../components/Breadcrumb"
import DocumentsVerification from "../../components/DocumentsVerification"
import Hero from "../../components/Hero"
import Layout from "../../components/Layout"

const VerificationPage = props => {
  const path = [
    { label: "Inicio", to: "/" },
    {
      label: "Verificación de documentos",
    },
  ]
  return (
    <Layout>
      <Hero
        image="/imgs/certificaciones-hero.jpg"
        text="Verificación de documentos"
      />
      <div className="container950">
        <Breadcrumb path={path} />
        <DocumentsVerification />
      </div>
    </Layout>
  )
}

export default VerificationPage
