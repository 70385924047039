import React, { useState } from "react"
import PropTypes from "prop-types"
import Input from "../Form/Input"
import Message from "../Message"
import HtmlParser from "react-html-parser"
import { useAxiosClient } from "../../service/useAxiosClient"
import Information from "../Information"
import { Fragment } from "react"
import TitleBlock from "../TitleBlock"

const DocumentsVerification = props => {
  const { docCode } = props
  const [code, setCode] = useState(docCode || "")
  const [showError, setShowError] = useState()
  const [docContent, setDocContent] = useState()

  const [, triggerLogin] = useAxiosClient(
    {},
    {
      manual: true,
    }
  )

  const handleChange = ({ value }) => setCode(value)

  const handleSubmit = e => {
    if (e) e.preventDefault()
    triggerLogin({
      url: "/check-document/",
      params: {
        code,
      },
    }).then(({ data }) => {
      if (Array.isArray(data) && data.length) {
        setDocContent(data[0])
      } else setShowError(true)
    })
  }

  const renderMessage = () => {
    if (!showError) return null
    return (
      <Message
        text="No existe ningún documento con el código introducido."
        type="error"
      />
    )
  }

  const renderContent = () => {
    if (docContent) return HtmlParser(docContent.content)
    return (
      <Fragment>
        <Information title="Por favor, introduzca el código del documento:" />
        {renderMessage()}
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            label="Código"
            className="w-auto"
            controlClassName="ml-4"
            value={code}
            onChange={handleChange}
          />
          <hr className="efpa-hr" />
          <button className="EfpaEurope-link" type="submit">
            Validar
          </button>
        </form>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <TitleBlock
        title={docContent ? "Contenido del documento" : "Validar documento"}
        icon="fas fa-file"
      />
      {renderContent()}
    </Fragment>
  )
}

DocumentsVerification.propTypes = {
  docCode: PropTypes.string,
}

export default DocumentsVerification
